<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import EventMappingStore from '@/views/EventsAndIncidents/EventMapping/EventMappingStore';
import SecondEquipmentPanelMapping
  from '@/views/EventsAndIncidents/EventMapping/SecondEquipmentPanelMapping.vue';
import SaveIcon from '@/assets/icons/save.svg';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';


export default {
  name: 'EventMapping',
  components: {
    SaveIcon,
    SecondEquipmentPanelMapping,

  },
  mixins: [formatters, dateHelpers],
  beforeCreate() {
    this.myStore = new EventMappingStore();
  },
  async created() {
    if (this.$route.params.id) {
      await this.myStore.loadAsync(this.$route.params.id);
    }
  },

  data() {
    return {
      loading: false,
    };
  },
  watch: {
    destEventCard: {
      handler() {
        this.myStore.reCompareCurrent();
      },
      deep: true
    },
    // 'this.myStore.state.destEventCard': function () {
    //   this.myStore.reCompare();
    // }
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['shortCircuitForms', 'shortCircuitPlaces', 'faultedPhases', 'cardInformations', 'technicalReasons']),
    model() {
      return this.myStore.state;
    },
    destEventCard() {
      return this.myStore.state.destEventCard;
    },
    sourceEventCard() {
      return this.myStore.state.sourceEventCard;
    },
    eventCompare() {
      return this.myStore.state.eventCompare;
    },
    showCopyAll() {
      return !this.myStore.state.destEventCard && this.myStore.state.sourceEventCard;
    },
    showDestEvent() {
      return this.myStore.state.destEventCard;
    },
    showSourceEvent() {
      return this.myStore.state.sourceEventCard;
    },
  },

  methods: {
    copy(name) {
      if (name === 'number') {
        if (!this.myStore.state.destEventCard && this.myStore.state.sourceEventCard) {
          const addEv = JSON.parse(JSON.stringify(this.myStore.state.sourceEventCard));
          /* Сбросили при копировании ИД карточки и все */
          addEv.id = '00000000-0000-0000-0000-000000000000';
          addEv.secondEquipmentPanelCards.forEach((el) => {
            el.sourceSepCardId = el.id;
            el.id = '00000000-0000-0000-0000-000000000000';
            el.statusId = 1;
            el.currentStatusId = 1;
            if (el.secondEquipmentCards) {
              el.secondEquipmentCards.forEach((el2) => {
                el2.id = '00000000-0000-0000-0000-000000000000';
              });
            }
          });

          this.myStore.state.destEventCard = addEv;
        }
      }
      if (this.myStore.state.destEventCard && this.myStore.state.sourceEventCard) {
        switch (name) {
          case 'eventTime':
            this.myStore.state.destEventCard.eventTime = this.myStore.state.sourceEventCard.eventTime;
            break;
          default:
            this.myStore.state.destEventCard[name] = JSON.parse(JSON.stringify({ v: this.myStore.state.sourceEventCard[name] })).v;
            break;
        }
        // this.myStore.reload();
      }
    },
    async handleSave() {
      if (!this.destEventCard || !this.sourceEventCard) return;
      this.loading = true;
      const updData = {
        eventCorrelationId: this.$route.params.id,
        sourceEventCardId: this.sourceEventCard.id,
        destEventCard: this.destEventCard
      };

      try {
        await EventCardCorrelationRepository.saveAsync(updData);
        this.model.sourceVariants = [];
        this.$message.success('Сохранено успешно');
      } catch (e) {
        this.$message.error(e.message);
      } finally {
        this.loading = false;
      }
    },
    handleBack() {
      this.$router.back();
    },
    async handleChangeSourceVariant(id) {
      await this.myStore.loadSourceVariantAsync(id);
    }
  },
};
</script>

<template>
  <div>
    <el-button class="pt-0" type="text" icon="el-icon-back" @click="handleBack">Назад</el-button>
    <el-button :disabled="loading && destEventCard" type="text" @click="handleSave">
      <SaveIcon/>
      Сохранить
    </el-button>

    <el-row :gutter="5" class="mt-1 my-label-14" v-if="showDestEvent">
      <el-col :span="4">Статус сопоставления события</el-col>
      <el-col :span="4">{{ model.statusName }}</el-col>
      <el-col :span="4">Сопоставлено устройств по событию</el-col>
      <el-col :span="4">{{ model.mappingCount }} из {{ model.totalMappingCount }}</el-col>
      <el-col :span="4">
        <el-select popper-class="search-select" size="mini" clearable
                   @change="handleChangeSourceVariant"
                   v-if="model.sourceVariants.length>0"
                   v-model="model.selectedSourceVariantId"
                   placeholder="">
          <el-option v-for="item in model.sourceVariants"
                     :key="item.id"
                     :label="item.number"
                     :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row></el-row>
    <div>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col span="6" class="my-label-14">Номер события:</el-col>
            <el-col span="9">
              <el-input
                :class="{'my-event-card-no-compare': eventCompare.number }"
                size="mini"
                v-model="destEventCard.number"></el-input>
            </el-col>
            <el-col span="1">q</el-col>
            <el-col span="4" class="my-label-14">Номер аварии</el-col>
            <el-col span="4">{{ destEventCard.incidentNumber }}</el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button
            :class="{'my-event-card-move': eventCompare.number }"
            v-if="showCopyAll"
            size="mini"
            icon="el-icon-d-arrow-left"
            circle
            @click="copy('number')"
          ></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Номер события:</el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-input
                :class="{'my-event-card-no-compare': eventCompare.number }"
                size="mini"
                v-model="sourceEventCard.number" readonly></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дата и время события:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-date-picker type="datetime"
                              :class="{'my-event-card-no-compare': eventCompare.eventTime }"
                              v-model="destEventCard.eventTime"
                              v-mask="'##.##.#### ##:##'"
                              size="mini"
                              placeholder="дд.мм.гггг чч:мм"
                              format="dd.MM.yyyy HH:mm"
                              style="width: 100%"
                              :picker-options="datePickerOptions"/>
            </el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button :class="{'my-event-card-move': eventCompare.eventTime }"
                     v-if="eventCompare.eventTime && showSourceEvent" size="mini"
                     icon="el-icon-d-arrow-left"
                     @click="copy('eventTime')"
                     circle></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дата и время события:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-date-picker type="datetime" readonly
                              :class="{'my-event-card-no-compare': eventCompare.eventTime }"
                              v-model="sourceEventCard.eventTime"
                              v-mask="'##.##.#### ##:##'"
                              size="mini"
                              placeholder="дд.мм.гггг чч:мм"
                              format="dd.MM.yyyy HH:mm"
                              style="width: 100%"
                              :picker-options="datePickerOptions"/>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col :span="model.labelSpan" class="my-label-14">Вид КЗ:</el-col>
            <el-col :span="model.dataSpan">
              <el-select popper-class="search-select" size="mini" clearable
                         :class="{'my-event-card-no-compare': eventCompare.shortCircuitFormId }"
                         v-model="destEventCard.shortCircuitFormId"
                         placeholder="">
                <el-option v-for="item in shortCircuitForms"
                           :key="item.id"
                           :label="item.code + ' (' + item.name + ')'"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button :class="{'my-event-card-move': eventCompare.shortCircuitFormId }"
                     v-if="eventCompare.shortCircuitFormId  && showSourceEvent" size="mini"
                     @click="copy('shortCircuitFormId')"
                     icon="el-icon-d-arrow-left" circle></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Вид КЗ:</el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select popper-class="search-select" size="mini" clearable
                         :class="{'my-event-card-no-compare': eventCompare.shortCircuitFormId }"
                         v-model="sourceEventCard.shortCircuitFormId"
                         placeholder="">
                <el-option v-for="item in shortCircuitForms"
                           disabled
                           :key="item.id"
                           :label="item.code + ' (' + item.name + ')'"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Место КЗ:</el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select popper-class="search-select" size="mini" clearable
                         :class="{'my-event-card-no-compare': eventCompare.shortCircuitPlaceId }"
                         v-model="destEventCard.shortCircuitPlaceId" placeholder="">
                <el-option v-for="item in shortCircuitPlaces.filter(t => t.actual)"
                           :key="item.id"
                           :label="item.code + ' (' + item.name + ')'"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button :class="{'my-event-card-move': eventCompare.shortCircuitPlaceId }"
                     v-if="eventCompare.shortCircuitPlaceId  && showSourceEvent" size="mini"
                     @click="copy('shortCircuitPlaceId')"
                     icon="el-icon-d-arrow-left" circle></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Место КЗ:</el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select popper-class="search-select" size="mini" clearable
                         :class="{'my-event-card-no-compare': eventCompare.shortCircuitPlaceId }"
                         v-model="sourceEventCard.shortCircuitPlaceId" placeholder="">
                <el-option v-for="item in shortCircuitPlaces.filter(t => t.actual)"
                           disabled
                           :key="item.id"
                           :label="item.code + ' (' + item.name + ')'"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Поврежденные фазы:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select popper-class="search-select" size="mini"
                         :class="{'my-event-card-no-compare': eventCompare.faultedPhases }"
                         clearable v-model="destEventCard.faultedPhases" multiple placeholder="">
                <el-option v-for="item in faultedPhases"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button :class="{'my-event-card-move': eventCompare.faultedPhases }"
                     v-if="eventCompare.faultedPhases  && showSourceEvent"
                     size="mini"
                     @click="copy('faultedPhases')"
                     icon="el-icon-d-arrow-left" circle></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Поврежденные фазы:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select popper-class="search-select" size="mini"
                         :class="{'my-event-card-no-compare': eventCompare.faultedPhases }"
                         clearable v-model="sourceEventCard.faultedPhases" multiple placeholder="">
                <el-option v-for="item in faultedPhases"
                           disabled
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Доп. информация:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select
                :class="{'my-event-card-no-compare': eventCompare.cardInformation }"
                multiple
                v-model="destEventCard.cardInformation" size="mini">
                <el-option
                  v-for="item in cardInformations"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.isDisabled"
                />
              </el-select>
            </el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button :class="{'my-event-card-move': eventCompare.cardInformation }"
                     v-if="eventCompare.cardInformation  && showSourceEvent"
                     size="mini"
                     @click="copy('cardInformation')"
                     icon="el-icon-d-arrow-left" circle></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Доп. информация:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-select
                :class="{'my-event-card-no-compare': eventCompare.cardInformation }"
                multiple
                v-model="sourceEventCard.cardInformation"
                size="mini">
                <el-option
                  disabled
                  v-for="item in cardInformations"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Описание события:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-input size="mini"
                        :class="{'my-event-card-no-compare': eventCompare.description  }"
                        v-model="destEventCard.description "
                        type="textarea"
                        :rows="3"
              ></el-input>
            </el-col>
          </el-row>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="2">
          <el-button :class="{'my-event-card-move': eventCompare.description }"
                     v-if="eventCompare.description  && showSourceEvent"
                     size="mini"
                     @click="copy('description')"
                     icon="el-icon-d-arrow-left" circle></el-button>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="11">
          <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
            <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Описание события:
            </el-col>
            <el-col :span="this.myStore.state.dataSpan">
              <el-input size="mini"
                        :class="{'my-event-card-no-compare': eventCompare.description }"
                        readonly
                        v-model="sourceEventCard.description"
                        type="textarea"
                        :rows="3"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="5" class="mt-1">
        <el-col :span="24">
          <el-button>Сопоставлено - Согласовано</el-button>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="5" class="mt-1">
      <el-col :span="11">
        <el-tabs v-if="showDestEvent" v-model="myStore.state.selectedSepId"
                 @tab-click="this.myStore.handleSepChange">
          <el-tab-pane v-for="(sec, i) in destEventCard.secondEquipmentPanelCards" :key="i"
                       :label="sec.secondEquipmentPanelName.substr(0,25)+'...'"
                       :name="sec.secondEquipmentPanelId"
          >
          </el-tab-pane>

        </el-tabs>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="2">&nbsp;</el-col>
      <el-col :span="11">
        <el-tabs v-if="showSourceEvent" v-model="myStore.state.selectedSepId"
                 @tab-click="this.myStore.handleSepChange">
          <el-tab-pane v-for="(sepc, i) in sourceEventCard.secondEquipmentPanelCards" :key="i"
                       :label="sepc.secondEquipmentPanelName.substr(0,25)+'...'"
                       :name="sepc.secondEquipmentPanelId"
          >
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <second-equipment-panel-mapping :my-store="myStore"></second-equipment-panel-mapping>
  </div>
</template>


<style>
.el-main {
  overflow-y: auto !important;
}

.my-event-card-no-compare .el-input__inner {
  color: #D65A31 !important;
}

.my-event-card-no-compare .el-textarea__inner {
  color: #D65A31 !important;
}

.my-event-card-move .el-icon-d-arrow-left {
  color: #D65A31;
}

</style>
