// eslint-disable-next-line max-classes-per-file
import { reactive, watch } from 'vue';
// import technicalReasons from '@/api/nsi/technicalReasons';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';

class EventMappingStore {
  constructor() {
    // watchEffect(() => {
    //   console.log(this.state.destEventCard.eventTime);
    // });

    watch(
      () => this.state.destEventCard,
      () => {
        this.reload();
      },
      { deep: true }
    );
  }

  state = reactive({
    techReasons: [],

    statusId: 0,
    statusName: '',
    // currentStatusId: 0,
    sourceVariants: [],
    selectedSourceVariantId: null,

    totalMappingCount: 3,
    mappingCount: 0,

    destEventCard: null, /* Куда */
    sourceEventCard: null, /* Откуда */
    eventCompare: {},
    sepCompare: new Map(),
    seCompare: new Map(),
    selectedSourceSep: 0,
    selectedDestSep: 0,
    selectedSourceSe: 0,
    selectedDestSe: 0,


    selectedSepId: 0,
    selectedSeId: 0,

    labelSpan: 8,
    dataSpan: 16,
    selectedDestSepCard: null,
    selectedSourceSepCard: null,
    selectedDestSeCard: null,
    selectedSourceSeCard: null
  });

  corSepStatuses = [
    {
      id: 1,
      name: 'Рассмотрение СО ЕЭС - Новое',
      prev: new Set([1])
    },
    {
      id: 2,
      name: 'Сопоставлено - согласие',
      prev: new Set([1, 2, 3])
    },
    {
      id: 3,
      name: 'Рассмотрение Субъектом - Корректировка',
      prev: new Set([1, 3])
    },
    {
      id: 4,
      name: 'Рассмотрение Субъектом - Удаление СО ЕЭС',
      prev: new Set([1, 4])
    },
    {
      id: 5,
      name: 'Рассмотрение СО ЕЭС - Корректировка',
      prev: new Set([3, 5])
    },
    {
      id: 6,
      name: 'Сопоставлено - Конфликт',
      prev: new Set([5, 6])
    },
    {
      id: 7,
      name: 'Рассмотрение СО ЕЭС - Не принято удаление Субъектом',
      prev: new Set([4, 7])
    },
    {
      id: 8,
      name: 'Сопоставлено - Удалено в СО ЕЭС',
      prev: new Set([7, 8])
    },
  ];

  getAllowedCorSepStatuses(id) {
    return this.corSepStatuses.filter((el) => el.prev.has(id));
  }

  async loadTechReasonsAsync(techReasons) {
    if (this.state.techReasons.length > 0) return;
    // const techReasons = await technicalReasons.getTechnicalReasonsWithPagination(1, 1000000, null, 'name', false);
    // if (techReasons.data) {
      this.state.techReasons = this.prepareTechReasons(techReasons);// this.prepareTechReasons(techReasons.data.items);
    // }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareTechReasons(data) {
    const items = [];
    data.forEach((el) => {
      if (!el.children || el.children.length === 0) {
        items.push(el);
      }
      el.children.forEach((el2) => {
        items.push(el2);
      });
    });
    return items.filter((el) => el.actual)
      .sort((a, b) => (a.code > b.code ? 1 : -1));
  }

  // load() {
  //   this.state.destEventCard = dest;
  //   this.state.sourceEventCard = source;
  //   this.compare(source, dest);
  // }

  async loadAsync(id) {
    const res = await EventCardCorrelationRepository.getAsync(id);
    this.state.sourceEventCard = res.sourceEventCard;
    this.state.destEventCard = res.destinationEventCard;

    this.prepareSeps(res.sourceEventCard);
    this.prepareSeps(res.destinationEventCard);

    this.state.statusId = res.statusId;
    this.state.statusName = res.statusName;
    this.state.currentStatusId = res.destinationEventCard?.satusId;


    if (!this.state.sourceEventCard && this.state.destEventCard) {
      this.state.sourceVariants = await EventCardCorrelationRepository.loadMappingEventAsync(this.state.destEventCard.primaryEquipmentId, this.state.destEventCard.eventTime);
    }


    this.reCompareCurrent();
  }

  async loadSourceVariantAsync(id) {
  this.state.sourceEventCard =  await EventCardCorrelationRepository.loadEventCardByIdAsync(id);
  this.reCompareCurrent();
  }

  // eslint-disable-next-line class-methods-use-this
  prepareSeps(sep) {
    if (sep && sep.secondEquipmentPanelCards) {
      // eslint-disable-next-line no-return-assign
      sep.secondEquipmentPanelCards.forEach((el) => el.currentStatusId = el.statusId);
    }
  }

  reload() {
    this.load();
    this.reselectAll();
  }

  reCompareCurrent() {
    this.compare(this.state.sourceEventCard, this.state.destEventCard);
  }

  compare(sEvent, dEvent) {
    if (!sEvent || !dEvent) return;

    this.state.sepCompare = new Map();
    this.state.seCompare = new Map();

    this.state.eventCompare = this.compareEvent(sEvent, dEvent);

    this.compareSeps(sEvent, dEvent);
  }

  // eslint-disable-next-line class-methods-use-this
  compareEvent(s, d) {
    const compE = {};
    compE.number = s.number !== d.number;
    compE.eventTime = s.eventTime !== d.eventTime;
    compE.shortCircuitFormId = s.shortCircuitFormId !== d.shortCircuitFormId;
    compE.shortCircuitPlaceId = s.shortCircuitPlaceId !== d.shortCircuitPlaceId;
    compE.faultedPhases = !this.compareArray(s.faultedPhases, d.faultedPhases);
    compE.cardInformation = !this.compareArray(s.cardInformation, d.cardInformation);
    compE.description = s.description !== d.description;
    return compE;
  }

  compareSeps(sEvent, dEvent) {
    if (sEvent.secondEquipmentPanelCards.length > 0 && dEvent.secondEquipmentPanelCards.length) {
      for (let i = 0; i < sEvent.secondEquipmentPanelCards.length; i++) {
        const dSep = dEvent.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sEvent.secondEquipmentPanelCards[i].secondEquipmentPanelId);
        if (dSep) {
          this.state.sepCompare.set(dSep.secondEquipmentPanelId, this.compareSep(sEvent.secondEquipmentPanelCards[i], dSep));
          this.compareSes(dSep.secondEquipmentPanelId, sEvent.secondEquipmentPanelCards[i], dSep);
        }
      }
    }
  }

  compareSep(s, d) {
    const compSep = {};
    compSep.estimationId = s.estimationId !== d.estimationId;
    compSep.energyObjectName = s.energyObjectName !== d.energyObjectName;
    compSep.cardInformation = !this.compareArray(s.cardInformation, d.cardInformation);
    compSep.technicalReasons = !this.compareTechnicalReasons(s.technicalReasons, d.technicalReasons);
    compSep.misoperationReasons = !this.compareArray(s.misoperationReasons, d.misoperationReasons);
    compSep.organizationalReasons = !this.compareOrganizationalReasons(s.organizationalReasons, d.organizationalReasons);
    return compSep;
  }

  compareSes(sepId, sSep, dSep) {
    if (sSep.secondEquipmentCards.length > 0 && dSep.secondEquipmentCards.length) {
      for (let i = 0; i < sSep.secondEquipmentCards.length; i++) {
        const dSe = dSep.secondEquipmentCards.find((el) => el.secondEquipmentId === sSep.secondEquipmentCards[i].secondEquipmentId);
        if (dSe) {
          this.state.seCompare.set(`${sepId}_${dSe.secondEquipmentId}`, this.compareSep(sSep.secondEquipmentCards[i], dSe));
        }
      }
    }
  }

  /**
   * Сравнение функций
   * @param s
   * @param d
   * @returns {SepCompare}
   */
  compareSe(s, d) {
    const compSe = {};
    compSe.estimationId = s.estimationId !== d.estimationId;
    compSe.cardInformation = !this.compareArray(s.cardInformation, d.cardInformation);
    compSe.technicalReasons = !this.compareTechnicalReasons(s.technicalReasons, d.technicalReasons);
    compSe.misoperationReasons = !this.compareArray(s.misoperationReasons, d.misoperationReasons);
    compSe.organizationalReasons = !this.compareOrganizationalReasons(s.organizationalReasons, d.organizationalReasons);
    return compSe;
  }

  getDestSepCard(sepId) {
    if (this.state.destEventCard) {
      return this.state.destEventCard.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sepId);
    }
    return null;
  }

  getSourceSepCard(sepId) {
    if (this.state.sourceEventCard) {
      return this.state.sourceEventCard.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sepId);
    }
    return null;
  }

  getDestSeCard(seId) {
    if (this.state.selectedDestSepCard) {
      return this.state.selectedDestSepCard.secondEquipmentCards.find((el) => el.secondEquipmentId === seId);
    }
    return null;
  }

  getSourceSeCard(sepId) {
    if (this.state.selectedSourceSepCard) {
      return this.state.selectedSourceSepCard.secondEquipmentCards.find((el) => el.secondEquipmentId === sepId);
    }
    return null;
  }

  reselectAll() {
    this.state.selectedDestSepCard = this.getDestSepCard(this.state.selectedSepId);
    this.state.selectedSourceSepCard = this.getSourceSepCard(this.state.selectedSepId);
    this.state.selectedDestSeCard = this.getDestSeCard(this.state.selectedSeId);
    this.state.selectedSourceSeCard = this.getSourceSeCard(this.state.selectedSeId);
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSepChange = (tab, e) => {
    this.reselectAll();
  };

// eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSeChange = (tab, e) => {
    this.state.selectedDestSeCard = this.getDestSeCard(this.state.selectedSeId);
    this.state.selectedSourceSeCard = this.getSourceSeCard(this.state.selectedSeId);
  };


  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSourceSepChange = (tab, e) => {
    this.state.selectedDestSep = tab.name;
  };

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleDestSepChange = (tab, e) => {
    this.state.selectedSourceSep = tab.name;
  };

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSourceSeChange = (tab, e) => {
    if (this.state.selectedDestSep === this.state.selectedSourceSep) {
      this.state.selectedDestSe = tab.name;
    } else {
      this.state.selectedDestSe = -1;
    }
  };

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleDestSeChange = (tab, e) => {
    if (this.state.selectedDestSep === this.state.selectedSourceSep) {
      this.state.selectedSourceSe = tab.name;
    } else {
      this.state.selectedSourceSe = -1;
    }
  };

  compareTechnicalReasons(s, d) {
    if (!s || !d) return false;
    if (s.length !== d.length) return false;
    return this.compareArray(s.map((el) => el.code), d.map((el) => el.code));
  }

  compareOrganizationalReasons(s, d) {
    if (!s || !d) return false;
    if (s.length !== d.length) return false;
    return this.compareArray(s.map((el) => `${el.code}${el.category}${el.organization}`), d.map((el) => `${el.code}${el.category}${el.organization}`));
  }

  // eslint-disable-next-line class-methods-use-this
  compareArray(s, d) {
    if (!s || !d) return false;
    if (s.length !== d.length) return false;

    // const sSet = new Set(s);
    const dSet = new Set(d);

    for (let i = 0; i < s.length; i++) {
      if (!dSet.has(s[i])) {
        return false;
      }
    }
    return true;
  }
}

export default EventMappingStore;



